import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const playerForm = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Player Form and Live Condition Rating"
        description="An explanation of the Form attribute in PES 2021, along with the weekly Live Condition Rating update."
      />
      <div className="container">
        <h1>Player Form and Live Condition Rating in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Player Form</li>
        </ul>
        <p>
          Player "Form" and Konami's weekly "Live Condition Rating" are 2
          important things to take into consideration when deciding which
          players to use before a game.
        </p>
        <p>
          In a nutshell, these 2 factors help determining the color of the
          arrows players will display before a game. If they have the arrow up
          (blue color), they get boosted attributes. If their arrow is down (red
          color), they will perform poorly.
        </p>
        <p>
          The big conclusion is that if you have many quality players in your
          squad, you should use as many A-condition players as possible. This
          also adds a bit of variety to the teams you will face every week.
        </p>
        <p>
          If every player is in C-condition that week, you should go for
          versatile players that have a high "Form" attribute value, as they
          should display "normal" (yellow) arrows often.
        </p>
        <p>Let's learn more about this.</p>
        <h2>Live Condition Rating</h2>
        <p>
          Condition Rating is usually updated weekly (every Thursday), assuming
          you're in the middle of a football season. When you load myClub, you
          will see the game's top players' condition for that week right away.
        </p>
        <p>
          If your players had a good week in real life, they will witness a
          “Form upgrade” in PES myClub as well, via Condition Rating. This only
          applies to matches that occured in the weekend.
        </p>
        <p>
          Condition Rating has 5 variants: A/B/C/D/E. A player who scores a
          hat-trick is pretty much guaranteed to be in A-condition for that
          week, while a player who was sent off will probably be in D-condition.
        </p>
        <p>
          Players in Condition Rating of "A" will very likely have the arrow up
          for pretty match every game, every time you pick them.
        </p>
        <p>
          It is unknown how much attributes are improved, since Konami hasn't
          mentioned this since the PES 6 guide book, back in 2006, but you can
          have a look at{" "}
          <a
            href="https://pes.neoseeker.com/wiki/Condition#PES_2018"
            target="_blank"
            rel="noopener noreferrer"
          >
            this chart for PES 2018
          </a>{" "}
          to have an idea of what to expect, provided they haven't changed it
          over the years.
        </p>
        <p>
          In any event, one thing's for sure: this simply means your players
          should perform better than usual if they are playing with the arrow
          up.
        </p>
        <p>
          If a player is injured in real life, he will be in Condition Rating of
          "C" until he gets back on the pitch (and sometimes in D-condition the
          week after the match they got injured). If you notice your player is
          in D-condition, you should avoid picking him, as he’s likely to have
          his arrow down for most games, resulting in poor performances.
        </p>
        <p>
          Condition Rating of "C" is the default state, and the one most players
          will usually be in. If the competitions are paused, C-condition will
          be applied to all players.
        </p>
        <p>
          <Link to="/legends/">Legends</Link> are the exception: they used to be
          just like the others, but Konami has decided to upgrade their
          Condition Rating to "B" after PES 2020 Data Pack 4, in February 2020.
        </p>
        <h2>The Form Attribute</h2>
        <p>
          You should also keep an eye on the “Form” attribute of each player,
          which is independent from what I mentioned above. To know your
          player's Form value, you can either check your player’s stats, or
          access{" "}
          <a
            href="http://pesdb.net/pes2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PES DB
          </a>
          . They range from 1 to 8.
        </p>
        <p>
          See this as a "Consistency" metric. The higher the number, the more
          consistent they are - Messi and Ronaldo, who consistently perform well
          throughout the year, have 7, while a generic White Ball can have 3.
        </p>
        <p>
          This means that Messi and Ronaldo will usually have their arrows in
          the middle for most matches (less fluctuation).
        </p>
        <p>
          Inconsistent players are more unpredictable. They are more likely to
          have an arrow up or down, and are less likely to stay in the middle
          (more fluctuation).
        </p>
        <p>
          This is backed by{" "}
          <a
            href="https://hanabi5541.net/pes2019/blog-107"
            target="_blank"
            rel="noopener noreferrer"
          >
            Japanese blogger Hanabi's findings
          </a>
          ; he tested how the "Form" stat of a player influences the probability
          of red/orange/yellow/green/blue arrows throughout 100 matches.
        </p>
        <p>You can find a summary of his conclusions below.</p>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Player Form study in PES"
        />
        <p>
          The big takeaway from this test is that Form 7 or 6 might actually be
          the most desirable ratings. We can see there was strong likelihood of
          the player displaying the blue or green arrow.
        </p>
        <p>
          The calculation "Form x Live Condition Rating" Konami performs is
          unknown, but if a player has a high form number and is in A-condition,
          you can be assured that he will perform with the arrow up almost every
          game.
        </p>
      </div>
    </Layout>
  )
}

export default playerForm

export const query = graphql`
  query {
    file(relativePath: { eq: "Graph-form-PES2019.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
